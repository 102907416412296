<template>
  <AuthGuard>
    <v-app app>
      <v-app-bar color="accent" app>
        <v-col sm="6" lg="3">
          <v-row align="center" justify="center">
            <v-img
              id="uaBrand"
              src="./assets/ua_brand.png"
              max-width="250"
              class="ml-0"
            ></v-img>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
        <v-col sm="6" lg="3">
          <v-row align="center" justify="end">
            <v-btn
              v-if="
                $router.currentRoute.name === 'admin' ||
                $router.currentRoute.name === 'reviewcolmap' ||
                $router.currentRoute.name === 'colmap'
              "
              dark
              color="#1e5288"
              class="mr-7"
              @click="$router.push('/')"
            >
              Home
            </v-btn>
            <v-btn
              v-else
              dark
              color="#1e5288"
              class="mr-7"
              @click="$router.push('/admintasks')"
            >
              Admin
            </v-btn>
            <v-btn
              v-if="
                $router.currentRoute.name === 'reviewcolmap' ||
                $router.currentRoute.name === 'colmap'
              "
              dark
              color="#1e5288"
              class="mr-7"
              @click="$router.push('/admintasks')"
            >
              Admin
            </v-btn>
          </v-row>
        </v-col>
      </v-app-bar>
      <v-main>
        <router-view></router-view>
      </v-main>
      <FNSVFooter />
    </v-app>
  </AuthGuard>
</template>

<script>
import AuthGuard from "@uarizona-fnsv/vue-doggo/AuthGuard"
import FNSVFooter from "./components/FNSVFooter.vue"

export default {
  name: "App",
  components: {
    AuthGuard,
    FNSVFooter,
  },

  beforeMount() {
    this.$auth.login()
  },
}
</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";
</style>
