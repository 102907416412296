import Vue from "vue"
import Swal from "sweetalert2"
import axios from "axios"
import Doggo from "@uarizona-fnsv/vue-doggo"
import * as Sentry from "@sentry/vue"
import { LicenseManager } from "ag-grid-enterprise"

import App from "./App.vue"
import router from "./router"
import api from "./utils/api"
import store from "./store"
import vuetify from "./plugins/vuetify"
import VuePapaParse from "vue-papa-parse"

// Sentry configuration
function getEnvironment() {
  const hostname = window.location.hostname
  if (hostname.includes("-dev")) return "dev"
  if (hostname.includes("cloudfront.net")) return "review"
  return "prod"
}

LicenseManager.setLicenseKey(
  "CompanyName=University of Arizona Financial Services Office,LicensedGroup=Financial Services-IT,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=0,AssetReference=AG-015281,ExpiryDate=12_August_2022_[v2]_MTY2MDI1ODgwMDAwMA==116e7222abf2813c45918e7c8b238dd9",
)

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue: Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    environment: getEnvironment(),

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // trackComponents: ["Header", "Navigation", "Footer"],
    // hooks: ["create", "mount"],

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/matchstick-dev\.fso\.arizona\.edu\/api/,
      /^https:\/\/matchstick\.fso\.arizona\.edu\/api/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

Vue.config.productionTip = false

Vue.use(Doggo, {
  amplify: {
    region: process.env.VUE_APP_COGNITO_REGION,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    domain: process.env.VUE_APP_COGNITO_DOMAIN,
    redirectSignIn: process.env.VUE_APP_COGNITO_SIGN_IN_URL,
  },
  disable: process.env.VUE_APP_DISABLE_AUTH,
})

Vue.use(VuePapaParse)

// Add global methods
Vue.prototype.$http = axios
Vue.prototype.$api = api // For internal API, comes with CSRF token.
Vue.prototype.$alert = Swal

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app")
