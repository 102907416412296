import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

const state = {
  isCorrectedFile: false,
  parsedData: [],
  fileType: null,
  testFile: null,
  priorYear: null,
  csvFile: null,
}

const mutations = {
  changeToCorrectedFile: (state, payload) => {
    state.isCorrectedFile = true
  },
  changeToNotCorrectedFile: (state, payload) => {
    state.isCorrectedFile = false
  },
  uploadData: (state, payload) => {
    Vue.set(state, "parsedData", payload)
  },
  changeFileType: (state, payload) => {
    Vue.set(state, "fileType", payload)
  },
  changeTestFile: (state, payload) => {
    Vue.set(state, "testFile", payload)
  },
  changePriorYear: (state, payload) => {
    Vue.set(state, "priorYear", payload)
  },
  uploadCsvFile: (state, payload) => {
    Vue.set(state, "csvFile", payload)
  },
}
const actions = {
  changeToCorrectedFileAction: (context, payload) => {
    context.commit("changeToCorrectedFile", payload)
  },
  changeToNotCorrectedFileAction: (context, payload) => {
    context.commit("changeToNotCorrectedFile", payload)
  },
  uploadDataActions: (context, payload) => {
    context.commit("uploadData", payload)
  },
  changeFileTypeAction: (context, payload) => {
    context.commit("changeFileType", payload)
  },
  changeTestFileAction: (context, payload) => {
    context.commit("changeTestFile", payload)
  },
  changePriorYearAction: (context, payload) => {
    context.commit("changePriorYear", payload)
  },
  uploadCsvFileAction: (context, payload) => {
    context.commit("uploadCsvFile", payload)
  },
}

const getters = {
  getCorrectedFileStatus: (state) => state.isCorrectedFile,
  getParsedData: (state) => state.parsedData,
  getFileType: (state) => state.fileType,
  getTestFile: (state) => state.testFile,
  getPriorYear: (state) => state.priorYear,
  getCsvFile: (state) => state.csvFile,
}

const modules = {}

export default new Vuex.Store({ state, mutations, actions, getters })
