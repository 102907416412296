var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AuthGuard',[_c('v-app',{attrs:{"app":""}},[_c('v-app-bar',{attrs:{"color":"accent","app":""}},[_c('v-col',{attrs:{"sm":"6","lg":"3"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-img',{staticClass:"ml-0",attrs:{"id":"uaBrand","src":require("./assets/ua_brand.png"),"max-width":"250"}})],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"sm":"6","lg":"3"}},[_c('v-row',{attrs:{"align":"center","justify":"end"}},[(
              _vm.$router.currentRoute.name === 'admin' ||
              _vm.$router.currentRoute.name === 'reviewcolmap' ||
              _vm.$router.currentRoute.name === 'colmap'
            )?_c('v-btn',{staticClass:"mr-7",attrs:{"dark":"","color":"#1e5288"},on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v(" Home ")]):_c('v-btn',{staticClass:"mr-7",attrs:{"dark":"","color":"#1e5288"},on:{"click":function($event){return _vm.$router.push('/admintasks')}}},[_vm._v(" Admin ")]),(
              _vm.$router.currentRoute.name === 'reviewcolmap' ||
              _vm.$router.currentRoute.name === 'colmap'
            )?_c('v-btn',{staticClass:"mr-7",attrs:{"dark":"","color":"#1e5288"},on:{"click":function($event){return _vm.$router.push('/admintasks')}}},[_vm._v(" Admin ")]):_vm._e()],1)],1)],1),_c('v-main',[_c('router-view')],1),_c('FNSVFooter')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }