import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue"),
    },
    {
      path: "/colmap",
      name: "colmap",
      component: () => import("./views/ColMap.vue"),
    },
    {
      path: "/reviewcolmap",
      name: "reviewcolmap",
      component: () => import("./views/ReviewColMap.vue"),
    },
    {
      path: "/admintasks",
      name: "admin",
      component: () => import("./views/Admin.vue"),
    },
  ],
})

// router.beforeEach((to, from, next) => {
//   document.title = to?.meta.title
//     ? `matchstick | ${to.meta.title}`
//     : "matchstick"
//   next()
// })

export default router
